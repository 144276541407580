import { Link, withPrefix } from "gatsby"
import React from "react"

const Shortcut = () => {
  return (
    <div className="na-skroty little show-for-large">
      <div className="row">
        <div className="large-3 medium-3 small-12 columns">
          <h5>Na skróty:</h5>
        </div>
        <div className="large-9 medium-9 small-12 columns">
          <div className="row collapse">
            <div className="large-6 medium-5 small-12 columns text-left large-text-right">
              <p>
                <Link
                  to={"/kontakt"}
                  className="ga_send_open"
                  data-open="bonpodarunkowy"
                ><span><img className="svg"
                                 src={withPrefix("images/phone.svg")}
                                 alt="Ikona telefon" /></span>Umów się na
                  wizytę</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Shortcut
