import React from "react"
import {Helmet} from "react-helmet"

import Layout from "../layout"
import Shortcut from "./shortcut"
import HeaderWhitenav from "../header-whitenav"
import {graphql, Link, useStaticQuery, withPrefix} from "gatsby"
import SEO from "../seo";

const OfferBase = ({children, offerTitle}) => {
  const {strapiOffer} = useStaticQuery(graphql`
      query {
          strapiOffer {
              categories {
                  title
                  slug
                  subcategories {
                      title
                      slug
                  }
              }
              entries {
                  title
                  image {
                      childImageSharp {
                          fluid(maxWidth: 1920, quality: 100) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
                  text
                  procedures {
                      title
                      price
                      isPromo
                      promo
                      old
                  }
                  leftHeader
                  leftContent
                  rightHeader
                  rightContent
                  category
                  metaDescription
              }
          }
      }
  `)

  function sanitize(text: string) {
    return text
      .replace(/([^>\\r\\n]?)(\\r\\n|\\n\\r|\\r|\\n)/g, "<br />")
      .replace(/(\\"|\\_|\\t)/g, "")
  }

  const offer = strapiOffer.entries.filter((entry: any) => {
    return entry.title.trim() === offerTitle.trim()
  })[0]

  // Added because bug in strapi
  if (!offer) {
    return <></>
  }

  const url = offer.image.childImageSharp.fluid.src

  const text = sanitize(offer.text)
  const leftHeader = sanitize(offer.leftHeader)
  const rightHeader = sanitize(offer.rightHeader)
  const leftContent = sanitize(offer.leftContent)
  const rightContent = sanitize(offer.rightContent)

  const proceduresJsx = offer.procedures.map((procedure: any, index: number) => {

    return (
      <div className={procedure.isPromo ? "zabieg is-promo" : "zabieg"} key={index}>
        <div className="row">
          <div className="large-7 medium-7 small-6 columns">
            <h3 className="normal">
              <a className="black" href="#">
                {procedure.title}
              </a>
            </h3>
          </div>
          <div className="large-5 medium-5 small-6 columns text-right">
            {procedure.isPromo &&
            <p className={"red"}>{procedure.promo}</p>
            }
            {procedure.isPromo &&
            <p className={"old"}>{procedure.old}</p>
            }
            {!procedure.isPromo &&
            <p className="extras">{procedure.price}</p>
            }
          </div>
        </div>
      </div>
    )
  })

  const {categories} = strapiOffer

  let activeCategory: any
  let activeSubcategory: any

  const categoriesJsx = categories.map((category: any, index: number) => {
    let display = "none"
    const subcategoriesJsx = category.subcategories.map((subcategory: any, subindex: number) => {
      let active = ""

      if (offer.title.trim() === subcategory.title.trim()) {
        active = "active"
        display = "block"
        activeCategory = category
        activeSubcategory = subcategory
      }
      return (
        <li key={subindex} className={active}>
          <Link to={`/oferta/${category.slug}/${subcategory.slug}`}>{subcategory.title}</Link>
        </li>
      )
    })

    return (
      <div className="category-box" key={index}>
        <div className="row">
          <div className="large-3 medium-1 small-2 columns">
            <h3 className="kreska margin-0">0{index + 1}</h3>
          </div>
          <div className="large-9 medium-11 small-10 columns">
            <p className="normal border-bottom border-bottom-black h6">{category.title} <i
              className="fa fa-angle-right" aria-hidden="true"></i></p>
          </div>
        </div>

        <div className={"category-menu no-hide"} style={{display}}>
          <div className="row">
            <div
              className="large-9 large-offset-3 medium-11 medium-offset-1 small-10 small-offset-2 columns end">
              <ul className="clear-ul">
                {subcategoriesJsx}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  })

  const categoriesForMobileJsx = categories.map((category: any, index: number) => {
    let active = "option"

    if (offer.category.trim() === category.title.trim()) {
      active = "option selected focus"
    }

    return (
      <li className={active} key={index} style={{position: "relative"}}>
        {category.title}
        <Link to={`/oferta/${category.slug}/${category.subcategories[0].slug}`} style={{
          color: "black",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%"
        }}/>
      </li>
    )
  })

  const subcategoriesForMobileJsx = activeCategory.subcategories.map((subcategory: any, index: number) => {
    let active = "option"

    if (offer.title.trim() === subcategory.title.trim()) {
      active = "option selected focus"
    }

    return (
      <li className={active} key={index} style={{position: "relative"}}>
        {subcategory.title}
        <Link to={`/oferta/${activeCategory.slug}/${subcategory.slug}`} style={{
          color: "black",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%"
        }}/>
      </li>
    )

  })


  return (
    <Layout>
      <SEO
        title={''}
        description={offer.metaDescription}
      />
      <Helmet
        meta={[
          {
            property: `og:title`,
            content: offer.title.trim()
          },
          {
            property: `og:url`,
            content: `https://dentandbeauty.pl/${activeCategory.slug}/${activeSubcategory.slug}`
          },
          {
            property: `og:image`,
            content: withPrefix(offer.image.childImageSharp.fluid.src)
          },
        ]}
      >
        <script type={'application/ld+json'}>
        {`
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://dentandbeauty.pl/${activeCategory.slug}/${activeSubcategory.slug}"
          },
          "headline": "${offer.title.trim()}",
          "image": "${withPrefix(offer.image.childImageSharp.fluid.src)}",
          "publisher": {
            "name": "dentandbeauty",
            "@type": "Organization",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dentandbeatuy.pl${withPrefix('images/og-image.jpg')}"
            }
          },
          "isAccessibleForFree": "True",
          "author": {
            "@type": "Person",
            "name": "dentandbeauty"
          }
        }
        `}
        </script>
      </Helmet>
      <HeaderWhitenav/>
      <div id="pagewrapper">
        <div className="row">
          <div className="large-9 large-push-3 medium-12 small-12 columns">
            <div className="row">
              <div className="large-9 medium-12 small-12 columns end">
                <Shortcut/>
                {children}
                <section className="section padding-top60 wow fadeInUp" data-wow-duration="1.5s"
                         style={{visibility: "hidden"}}>

                  <div className="category-head photobg" style={{backgroundImage: `url(${url})`}}>
                    <h1 className="show-for-large">{offer.category}</h1>
                    <div className="categpry-mobile hide-for-large">
                      <div className="nice-select add-wide large white wide">
                        <span className="current">{offer.category}</span>
                        <ul className={"list"}>
                          {categoriesForMobileJsx}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="large-12 medium-12 small-12 columns show-for-large">
                      <h2 className="border-bottom h3">
                        {offer.title}
                        <span className="extras"/>
                      </h2>
                    </div>
                    <div className="large-12 medium-12 small-12 columns hide-for-large">
                      <p className="gray2 margin-0">
                        Zmień zabieg
                      </p>
                      <div className="row collapse">
                        <div className="large-12 columns">
                          <div className="nice-select large add-wide select-mobile wide">
                            <span className="current">{offer.title}</span>
                            <ul className="list">
                              {subcategoriesForMobileJsx}
                            </ul>
                          </div>
                          <div className="clearfix"></div>
                          <div className="price margin-config">
                            <span className="extras"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-part">
                    <div dangerouslySetInnerHTML={{__html: text}}/>
                    <section className="content-padding-top">
                      <div className="row">
                        {leftHeader.trim().length !== 0 && leftContent.trim().length !== 0 &&
                        <div className="large-6 medium-6 small-12 columns">
                          <p className="big">
                            <strong dangerouslySetInnerHTML={{__html: leftHeader}}></strong>
                          </p>
                          <p dangerouslySetInnerHTML={{__html: leftContent}}/>
                        </div>
                        }
                        {rightHeader.trim().length !== 0 && rightContent.trim().length !== 0 &&
                        <div className="large-6 medium-6 small-12 columns">
                          <p className="big">
                            <strong dangerouslySetInnerHTML={{__html: rightHeader}}/>
                          </p>
                          <p dangerouslySetInnerHTML={{__html: rightContent}}/>
                        </div>
                        }
                      </div>
                    </section>
                    <section>
                      <h2 className="border-bottom h3">Zabiegi</h2>
                      {proceduresJsx}
                    </section>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="large-3 large-pull-9 medium-12 small-12 columns wow fadeInUp show-for-large"
               data-wow-duration="1.5s" style={{visibility: "hidden"}}>
            <Link to="/cennik#is-promo"
                  className="przecenione border-bottom border-bottom-black show-for-large">Pokaż <span
              className="red">przecenione</span> oferty</Link>
            {categoriesJsx}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OfferBase
